import { createSlice } from '@reduxjs/toolkit';
import { initializeDeck, drawCard } from '../utils/cardUtils';  // Import card utilities

const initialState = {
  players: [],
  currentPlayer: null,  // The ID of the current player
  deck: initializeDeck(),
  communityCards: [],
  pot: 0,
  currentBet: 0,
  gamePhase: 'pre-flop',  // Game phases: 'pre-flop', 'flop', 'turn', 'river', 'showdown'
  turnCount: 0,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resetGame(state) {
      state.players.forEach(player => {
        player.hasFolded = false;
        player.hasPlayed = false;
        player.cards = [];
      });
      state.currentPlayer = null;
      state.deck = initializeDeck();
      state.communityCards = [];
      state.pot = 0;
      state.currentBet = 0;
      state.turnCount = 0;
    },
    startGame(state) {
      if (state.players.length > 0) {
        state.currentPlayer = state.players[Math.floor(Math.random() * state.players.length)].id;
        state.players.forEach(player => {
          player.cards = [drawCard(state), drawCard(state)];
        });
      } else {
        state.currentPlayer = null;
        console.warn('No players available to start the game.');
      }
    },
    placeBet(state, action) {
      const { playerId, amount } = action.payload;
      const player = state.players.find(p => p.id === playerId);
      player.chips -= amount;
      state.pot += amount;
      state.currentBet = amount;

      player.hasPlayed = true;
      state.turnCount++;
    },
    fold(state, action) {
      const player = state.players.find(p => p.id === action.payload);
      player.hasFolded = true;
      player.hasPlayed = true;
      state.turnCount++;
    },
    nextTurn(state) {
      const currentPlayerIndex = state.players.findIndex(player => player.id === state.currentPlayer);

      if (currentPlayerIndex === -1) {
        console.warn('Next turn called without a current player.');
        return;
      }

      let playerIndex = (currentPlayerIndex + 1) % state.players.length;
      let nextStagePlayer = null;
      let nextTurnPlayer = null;
    
      // find the next player in the turn, or if no such player exists, the next player in the next stage
      while (playerIndex !== currentPlayerIndex) {
        const player = state.players[playerIndex];
        if (!nextStagePlayer && !player.hasFolded){
          nextStagePlayer = player;
        }
        if (!player.hasPlayed && !player.hasFolded){
          nextTurnPlayer = player;
          break;
        }
        playerIndex = (playerIndex + 1) % state.players.length;
      }

      if ( nextTurnPlayer ) {
        state.currentPlayer = nextTurnPlayer.id;
      } else if( nextStagePlayer ) {
        state.currentPlayer = nextStagePlayer.id;
      } else {
        console.warn('No active players');
      }
    },
    nextPhase(state) {
      const phases = ['pre-flop', 'flop', 'turn', 'river', 'showdown'];
      const currentIndex = phases.indexOf(state.gamePhase);
      if (currentIndex + 1 >= phases.length) {
        return;
      }
      state.gamePhase = phases[currentIndex + 1];
      console.log("Game phase is now " + state.gamePhase);

      state.players.forEach(player => {
        player.hasPlayed = false;
      });

      if ( state.gamePhase == 'flop' ) {
        // flop 3 cards
        while(state.communityCards.length < 3){
          state.communityCards.push(drawCard(state));
        }
      } else if (state.gamePhase === 'turn' || state.gamePhase === 'river') {
        // Draw 1 additional community card for "turn" and "river"
        if (state.communityCards.length < (state.gamePhase === 'turn' ? 4 : 5)) {
          state.communityCards.push(drawCard(state));
        }
      }
    },
    updatePlayers: (state, action) => {
      state.players = action.payload;
    }
  }
});

// Helper function to check if all active players have played in the phase
export function allPlayersHavePlayed(state) {
  const activePlayers = state.players.filter(player => !player.hasFolded);
  return activePlayers.length > 0 && activePlayers.every(player => player.hasPlayed);
}

export const { resetGame, startGame, placeBet, fold, nextTurn, nextPhase, updatePlayers } = gameSlice.actions;
export default gameSlice.reducer;
