// In authHelpers.js

import { signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { ref, set, get, onValue, query, equalTo, orderByChild, onDisconnect } from 'firebase/database';
import { auth, database } from '../firebase.js';
import { updatePlayers } from '../store/gameSlice';

// Generate an anonymous username if a user does not enter their own
function generateAnonymousUsername() {
  const adjectives = [
    "Amazing", "Brave", "Charming", "Dazzling", "Eager",
    "Fancy", "Gentle", "Happy", "Inventive", "Jolly",
    "Kind", "Lively", "Mighty", "Nice", "Optimistic",
    "Proud", "Quick", "Radiant", "Strong", "Thoughtful",
    "Unique", "Vibrant", "Witty", "Young", "Zealous"
  ];
  
  const scientists = [
    "Einstein", "Curie", "Newton", "Galileo", "Hawking",
    "Darwin", "Tesla", "Feynman", "Bohr", "Faraday",
    "Edison", "Turing", "Pasteur", "Maxwell", "Kepler",
    "Franklin", "Lovelace", "Hubble", "Mendel", "Planck",
    "Rutherford", "Sagan", "Heisenberg", "Dirac", "Godel"
  ];

  // Generate a random index for the adjective and scientist arrays
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomScientist = scientists[Math.floor(Math.random() * scientists.length)];

  // Return the combination
  return `${randomAdjective} ${randomScientist}`;
}

// Initialize anonymous authentication and prompt for username if needed
export const initializeAuthentication = (setUserId, setUsername) => {
  let userSetupInProgress = false;
  onAuthStateChanged(auth, (authUser) => {
    if(!userSetupInProgress){
      userSetupInProgress = true;
      if (authUser) {
        // User is already signed in, set up the player and track presence
        createOrRetrieveInteractivePlayer(authUser.uid, setUserId, setUsername).then((userName) => {
          console.log(`User ${userName} signed in with UID ${authUser.uid}`);
          trackUserPresence(authUser.uid, userName);
          userSetupInProgress = false;
        })
      } else {
        // Sign in anonymously if not signed in
        console.log('Not signed in. Creating anonymous login.');
        signInAnonymously(auth)
          .then((userCredential) => {
            authUser = userCredential.user;
            createOrRetrieveInteractivePlayer(authUser.uid, setUserId, setUsername).then((userName) => {
              console.log(`User ${userName} signed in with UID ${authUser.uid}`);
              trackUserPresence(authUser.uid, userName);
              userSetupInProgress = false;
            });
          })
          .catch((error) => {
            console.error('Error during anonymous sign-in:', error);
            userSetupInProgress = false;
          });
      }
    }
  });
};

// Prompt for username and store it in the database if not already set
export const createOrRetrieveInteractivePlayer = (userId, setUserId, setUsername) => {
  const playerRef = ref(database, `players/${userId}`);

  return get(playerRef).then((snapshot) => {
    let playerName;
    if (!snapshot.exists() || !snapshot.val().username) {
      // Prompt the user for their name if not already set
      const enteredName = prompt("Please enter your name:", "Player");
      playerName = enteredName ? enteredName : generateAnonymousUsername();

      // Save the player's name and initial data to the database
      set(playerRef, {
        username: playerName,
        chips: 1000000,
        virtual: false
      });

      console.log(`Created user: ${playerName}${enteredName ? '' : ' (generated)'}`);
      setUsername(playerName);
    } else {
      // If the username already exists, set it directly
      playerName = snapshot.val().username;
      console.log(`Found existing user: ${playerName}`);
      setUsername(playerName);
    }

    // Update the user ID in state
    setUserId(userId);

    // Return the playerName for further processing
    return playerName;
  }).catch((error) => {
    console.error('Error checking or setting player data:', error);
    alert('An error occurred while setting up your account. Please try again.');
    return null;
  });
};

// Create or retrieve a virtual player
const createOrRetrieveVirtualPlayer = async (index) => {
  const username = generateAnonymousUsername();
  const playerRef = ref(database, `players/virtual-${index}`);

  const snapshot = await get(playerRef);
  if (!snapshot.exists()) {
    // Create a new virtual player if it doesn't exist
    await set(playerRef, {
      username,
      chips: 1000000,
      virtual: true
    });
    console.log(`Created virtual player: ${username}`);
  } else {
    console.log(`Retrieved existing virtual player: ${snapshot.val().username}`);
  }

  return {
    id: `virtual-${index}`,
    name: snapshot.exists() ? snapshot.val().username : username,
    chips: 1000000,
    cards: [],
    hasFolded: false,
    hasPlayed: false
  };
};

const startHeartbeat = (userId) => {
  return 
}

// Track user presence and update the database
const trackUserPresence = (userId, userName) => {
  const userStatusRef = ref(database, `players/${userId}/status`);
  const lastActiveRef = ref(database, `players/${userId}/lastActive`);
  const connectedRef = ref(database, '.info/connected');
  let heartbeatInterval = null;

  // Monitor connection state
  onValue(connectedRef, (snapshot) => {
    if (snapshot.val() === true) {
      if(!heartbeatInterval){
        heartbeatInterval = setInterval( () => {
          set(lastActiveRef, Date.now());
        }, 15000);
      }

      // User is online, set the status to 'online'
      console.log(`User ${userName} is now online.`);
      set(userStatusRef, 'online');

      // FIXME: In case onDisconnect() is not called, such a browser crash,
      // the user will remain with an `online` status.
      onDisconnect(userStatusRef).set('offline');
    } else {
      console.log(`User ${userName} is now offline.`);
      // FIXME: how to disconnect from real-time database?
      clearInterval(heartbeatInterval);
      heartbeatInterval = null;
    }
  });
};

const fetchOnlineUsers = async () => {
  const playersRef = ref(database, 'players');
  const onlinePlayersQuery = query(playersRef, orderByChild('status'), equalTo('online'));

  try {
    const snapshot = await get(onlinePlayersQuery);
    if (snapshot.exists()) {
      const onlinePlayers = snapshot.val();
      return onlinePlayers;
    }
  } catch (error) {
    console.error('Error fetching online players: ', error);
  }
  return {};
};

// Update the players list in the Redux store
export const updatePlayersState = async (dispatch) => {
  const playersRef = ref(database, 'players');

  try {
    const snapshot = await get(playersRef);
    const players = snapshot.val() || {};
    const loggedInPlayers = Object.keys(players)
      .filter(id => !players[id].virtual)
      .filter(id => players[id].status === "online")
      .map((id) => ({
        id,
        name: players[id].username,
        chips: players[id].chips,
        cards: players[id].cards,
        hasFolded: false,
        hasPlayed: false
      }));

    // Fill in remaining players with virtual players up to 6
    const totalPlayers = loggedInPlayers.length;
    for (let i = totalPlayers; i < 6; i++) {
      const virtualPlayer = await createOrRetrieveVirtualPlayer(i);
      loggedInPlayers.push(virtualPlayer);
    }

    // Dispatch the action to update players in the Redux store
    dispatch(updatePlayers(loggedInPlayers));
  } catch (error) {
    console.error('Error updating players state:', error);
  }
};
