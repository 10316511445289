// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

canvas {
  display: block;
  margin: 0 auto;
}

button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
}

div {
  margin: 5px;
}

div .center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: Arial, sans-serif;\n}\n\ncanvas {\n  display: block;\n  margin: 0 auto;\n}\n\nbutton {\n  padding: 10px 20px;\n  margin: 10px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\ndiv {\n  margin: 5px;\n}\n\ndiv .center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
