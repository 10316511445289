import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const PokerTable = () => {
  const canvasRef = useRef(null);
  const players = useSelector(state => state.game.players);
  const communityCards = useSelector(state => state.game.communityCards);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Draw poker table
    ctx.fillStyle = '#006400';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw community cards (if any)
    communityCards.forEach((card, index) => {
      drawCard(ctx, card, 175 + index * 150, 50, 2.0);
    });

    // Draw player seats and cards
    players.forEach((player, index) => {
      drawPlayerSeat(ctx, player, 100 + index * 150, 300);
    });
  }, [players, communityCards]);

  const drawCard = (ctx, card, x, y, scale = 1.0) => {
    const cardWidth = 50 * scale;
    const cardHeight = 70 * scale;
    ctx.fillStyle = 'white';
    ctx.fillRect(x, y, cardWidth, cardHeight);  // Card outline

    // Measure text width for rank and suit
    const fontSize = Math.floor(12 * scale);
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = 'black';
    const rankWidth = ctx.measureText(card.rank).width;
    const suitWidth = ctx.measureText(card.suit).width;
    const centerX = x + (cardWidth / 2);
    ctx.fillText(`${card.rank}`, centerX - rankWidth / 2, y + (0.3 * cardHeight));
    ctx.fillText(`${card.suit}`, centerX - suitWidth / 2, y + (0.7 * cardHeight));
  };

  const drawPlayerSeat = (ctx, player, x, y) => {
    ctx.fillStyle = 'white';
    ctx.font = "16px Arial";
    ctx.fillText(`${player.name}`, x, y);
    // FIXME: Shouldn't players have cards here?
    if( player.cards ) {
      player.cards.forEach((card, index) => {
        drawCard(ctx, card, x + index * 60, y + 20);
      });
    }
  };

  return <canvas ref={canvasRef} width={1024} height={400} />;
};

export default PokerTable;
