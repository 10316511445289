import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { placeBet, fold, nextTurn, nextPhase } from '../store/gameSlice';
import { allPlayersHavePlayed } from '../store/gameSlice';

const PlayerActions = () => {
  const dispatch = useDispatch();
  const currentPlayer = useSelector(state => state.game.currentPlayer);
  const currentBet = useSelector(state => state.game.currentBet);
  const gameState = useSelector(state => state.game);
  const turnCount = useSelector(state => state.game.turnCount);

  const handleBet = () => {
    dispatch(placeBet({ playerId: currentPlayer, amount: Math.max(currentBet, 100000) }));
  };

  const handleFold = () => {
    dispatch(fold(currentPlayer));
  };

  useEffect(() => {
    // account for turn state being reset
      const player = gameState.players[currentPlayer];
      dispatch(nextTurn());
      if (allPlayersHavePlayed(gameState)) {
        dispatch(nextPhase());
      }
  }, [turnCount, dispatch]);

  return (
    <div>
      <button onClick={handleBet}>Bet</button>
      <button onClick={handleFold}>Fold</button>
    </div>
  );
};

export default PlayerActions;
