import React from 'react';
import { useSelector } from 'react-redux';

const PlayerSeat = ({ player, isCurrentPlayer }) => {
  const { name, chips, cards = [], hasFolded, hasPlayed } = player;

  // Set a different style for the current player (highlight)
  const seatStyle = {
    textAlign: 'center',
    padding: '10px',
    border: isCurrentPlayer ? '3px solid yellow' : '1px solid gray',
    backgroundColor: hasFolded ? '#f5f5f5' : 'white',
    opacity: hasPlayed || hasFolded ? 0.5 : 1,
  };

  return (
    <div style={seatStyle}>
      <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{name}</div>
      <div style={{ color: hasFolded ? 'red' : 'black' }}>
        {hasFolded ? 'Folded' : `Chips: ${chips}`}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {cards.map((card, index) => (
          <div key={index} style={{ margin: '0 5px', fontSize: '18px', fontWeight: 'bold' }}>
            {card.rank}
            <br/>{card.suit}
          </div>
        ))}
      </div>
    </div>
  );
};

const PlayerSeats = () => {
  const players = useSelector(state => state.game.players);
  const currentPlayerId = useSelector(state => state.game.currentPlayer);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '20px' }}>
      {players.map(player => (
        <PlayerSeat
          key={player.id}
          player={player}
          isCurrentPlayer={player.id === currentPlayerId}
        />
      ))}
    </div>
  );
};

export default PlayerSeats;
