import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import gameReducer from './store/gameSlice';  // Importing the game reducer for managing the game state
import App from './App';
import './styles/styles.css';  // Custom styles for the poker table

// Create Redux store
const store = configureStore({
  reducer: {
    game: gameReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
