import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PokerTable from './components/PokerTable';
import PlayerActions from './components/PlayerActions';
import PlayerSeats from './components/PlayerSeat';
import { resetGame, startGame } from './store/gameSlice';
import { initializeAuthentication, updatePlayersState } from './utils/authHelpers';

const App = () => {
  const dispatch = useDispatch();
  const gamePhase = useSelector(state => state.game.gamePhase);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState(null);
  const [playersLoaded, setPlayersLoaded] = useState(false);

  useEffect(() => {
    // Initialize authentication and load players
    initializeAuthentication(setUserId, setUsername);
  }, []);

  useEffect(() => {
    if (userId && username) {
      // After authentication, load the players from the database
      updatePlayersState(dispatch).then(() => {
        setPlayersLoaded(true);
      }).catch((error) => {
        console.error('Error loading players:', error);
      });
    }
  }, [userId, username, dispatch]);

  const handleStartGame = () => {
    dispatch(resetGame());
    dispatch(startGame());
  };

  const handleNewGame = () => {
    dispatch(resetGame());
  };

  // Render the game only if players are loaded and user is authenticated
  if (!userId || !username || !playersLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ textAlign: 'center', margin: '20px 0', fontSize: '20px', fontWeight: 'bold' }}>
        Current Phase: {gamePhase.toUpperCase()}
      </div>
      <PokerTable />
      <PlayerSeats />
      <PlayerActions />
      <button onClick={handleStartGame}>Start Game</button>
      <button onClick={handleNewGame}>New Game</button>
    </div>
  );
};

export default App;
