// Function to initialize a deck of 52 cards (standard deck)
export function initializeDeck() {
    const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
    const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
    const deck = [];
  
    suits.forEach(suit => {
      ranks.forEach(rank => {
        deck.push({ rank, suit });
      });
    });
  
    return shuffleDeck(deck);  // Return a shuffled deck
  }
  
  // Function to shuffle the deck (Fisher-Yates shuffle algorithm)
  export function shuffleDeck(deck) {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];  // Swap cards
    }
    return deck;
  }
  
  // Function to draw a card from the deck
  export function drawCard(state) {
    if (state.deck.length === 0) {
      console.warn('The deck is empty!');
      return null;
    }
  
    return state.deck.pop();  // Draw from the end of the deck (last card)
  }
  