import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator, forceWebSockets } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDdi5QeOGafL1pbVNCEDBLtI86TwFB8I4c",
  authDomain: "founder-mode-the-game.firebaseapp.com",
  //authDomain: "founder-mode.nullptr.com",
  databaseURL: "https://founder-mode-the-game-default-rtdb.firebaseio.com",
  projectId: "founder-mode-the-game",
  storageBucket: "founder-mode-the-game.appspot.com",
  messagingSenderId: "1096583101014",
  appId: "1:1096583101014:web:f93a776ac6c8b469a599f7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const functions = getFunctions(app);

// use WebSockets instead of long polling for connections to Firebase real-time database
forceWebSockets(true);

// Check if we're in development mode
if (process.env.NODE_ENV === 'development') {
  // Connect to Firebase emulators using environment variables
  connectAuthEmulator(
    auth,
    `http://${process.env.FIREBASE_EMULATOR_HOST}:${process.env.FIREBASE_EMULATOR_AUTH_PORT}`,
    { disableWarnings: true });
  connectDatabaseEmulator(
    database,
    process.env.FIREBASE_EMULATOR_HOST,
    process.env.FIREBASE_EMULATOR_DATABASE_PORT);
  connectFunctionsEmulator(
    functions,
    process.env.FIREBASE_EMULATOR_HOST,
    process.env.FIREBASE_EMULATOR_FUNCTIONS_PORT
  );
}

export { auth, database };
